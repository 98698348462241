
import {defineComponent, ref} from "vue";
import {castData} from "@/core/data/data_prepare";
import {Api} from "@/store/enums/StoreEnums";
import {data as empData, rules as empRules, definition as empDefinition} from "@/model/employee";
import router from "@/router";
import {getEnum} from "@/core/helpers/enums";
import ApiService from "@/core/services/ApiService";
import apiService from "@/core/services/ApiService";

export default defineComponent({
  name: "AddEmployeeFormBase",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const formData = ref(empData);
    const rules = ref(empRules);

    loading.value = false;
    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          console.log(formData.value);
          let data = castData(formData.value, empDefinition);
          ApiService.postFrom(Api.ADD_EMPLOYEE, data)
            .then(response => {
              router.push({
                name: "addEmployeeStep2",
                params: {id_emp: response.data.id}
              });
            })
            .catch(({response}) => {
              console.error(response);
              if (!isNaN(response)) {
              }
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading
    };
  },
  data() {
    return {
      last_internal_id: 0,
      last_id: 0,
      gender_enum: [],
      nationality_enum: [],
      relationship_enum: [],
    }
  },
  created() {
    getEnum('gender').then(({data}) => {
      this.gender_enum = data;
    });
    getEnum('nationality').then(({data}) => {
      console.log(data);
      this.nationality_enum = data;
    });
    getEnum('relationshipStatus').then(({data}) => {
      this.relationship_enum = data;
    });
    apiService.get(Api.GET_EMPLOYEE_LAST_ID).then(({data}) => {
      console.log(data);
      if (data['last_employee_id'] !== data['last_employee_internal_id']) {
        this.last_internal_id = data['last_employee_internal_id'];
      }
      this.formData.internal_id = data['last_employee_internal_id'] + 1;
      this.last_id = data['last_employee_id'];
    });
    let secondaryBtn: HTMLElement = document.querySelector("[data-kt-toggle-name='aside-minimize']") as HTMLElement;
    if (secondaryBtn && !secondaryBtn.classList.contains('active')) {
      secondaryBtn.click()
    }
  },
  methods: {}

});
