
import {defineComponent, onMounted} from "vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import AddEmployeeForm from "@/components/employee/forms/AddEmployeeRequiredInformations.vue";


export default defineComponent({
  name: "EmployeeOverview",
  components: {
    AddEmployeeForm,
  },

  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Výběř Přiřazení", ["Zaměstnanci"]);
    });
  }
});
